// Auth
export const User = (state) => state.auth.user
// Admin Me
export const Me = (state) => state.admins.me
export const CmsSettings = (state) => state.admins.me?.cmsSettings
// Service Detail
export const ServiceDetailsList = (state) => state.serviceDetails.list
// Status Bar
export const StatusBarData = (state) => state.statusBar
// Search Tool
export const SearchToolParams = (state) => state.searchTool.params
// Modal Settings
export const ModalSettingsLoading = (state) => state.modalSettings.isLoadingOpen
