import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { cloneDeep } from "lodash"
import { t } from "i18next"

export const statusObj = {
  success: "success",
  error: "error",
  warning: "warning"
}

const initialState = {
  status: null,
  text: "",
  reviewData: null,
  open: false
}

const statusBarSlice = createSlice({
  name: "statusBarSlice",
  initialState,
  reducers: {
    resetStatusBar(state) {
      return initialState
    },
    setStatusBarData(state, { payload }) {
      let cloneState = cloneDeep(state)
      let errorCount = 0
      let havePassed = false

      if (payload.reviewData?.length) {
        payload.reviewData.forEach((item) => {
          if (item.errors.length == 0) {
            havePassed = true
          } else {
            errorCount++
          }
        })
        if (errorCount) {
          if (havePassed) {
            cloneState.text = t("STATUS_BAR_warning_message", {
              count: errorCount
            })
            cloneState.status = statusObj.warning
          } else {
            cloneState.text = t("STATUS_BAR_error_message")
            cloneState.status = statusObj.error
          }
        } else {
          cloneState.text = t("STATUS_BAR_success_message")
          cloneState.status = statusObj.success
        }
      }

      return {
        ...cloneState,
        ...payload
      }
    }
  },
  extraReducers: {}
})

export const { resetStatusBar, setStatusBarData } = statusBarSlice.actions
export default statusBarSlice.reducer
