import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { ResourcesApi } from "../apis"

export const getResources = createAsyncThunk("resources/getResources", async (payload) => {
  return await ResourcesApi.getResources(payload)
})

export const postResources = createAsyncThunk("resources/postResources", async (file) => {
  let uploadFile = new FormData()
  uploadFile.append("file", file)
  return await ResourcesApi.postResources(uploadFile)
})

const initialState = {}

const resourcesSlice = createSlice({
  name: "resources",
  initialState,
  reducers: {},
  extraReducers: {}
})

export const {} = resourcesSlice.actions
export default resourcesSlice.reducer
