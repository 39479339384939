import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  open: false,
  params: {}
}

const searchToolSlice = createSlice({
  name: "searchToolSlice",
  initialState,
  reducers: {
    setSearchToolOpen(state, { payload }) {
      state.open = payload
    },
    resetSearchTool(state) {
      return initialState
    },
    setSearchToolData(state, { payload }) {
      state.params = payload
    }
  },
  extraReducers: {}
})

export const { resetSearchTool, setSearchToolData, setSearchToolOpen } = searchToolSlice.actions
export default searchToolSlice.reducer
