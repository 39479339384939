import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { CustomersApi } from "../apis"

export const getCustomers = createAsyncThunk("customers/getCustomers", async (payload) => {
  return await CustomersApi.getCustomers(payload)
})

export const postCustomer = createAsyncThunk("customers/postCustomer", async (payload, { rejectWithValue }) => {
  try {
    return await CustomersApi.postCustomer(payload)
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const getCustomerById = createAsyncThunk("customers/getCustomerById", async (payload, { rejectWithValue }) => {
  try {
    return await CustomersApi.getCustomerById(payload)
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const putCustomerById = createAsyncThunk("customers/putCustomerById", async (payload, { rejectWithValue }) => {
  try {
    return await CustomersApi.putCustomerById(payload)
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const getCustomerDollarBalanceById = createAsyncThunk(
  "customers/getCustomerDollarBalanceById",
  async (payload, { rejectWithValue }) => {
    try {
      return await CustomersApi.getCustomerDollarBalanceById(payload)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const getCustomerPaymentsById = createAsyncThunk(
  "customers/getCustomerPaymentsById",
  async (payload, { rejectWithValue }) => {
    try {
      return await CustomersApi.getCustomerPaymentsById(payload)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

const initialState = {}

const customersSlice = createSlice({
  name: "customers",
  initialState,
  reducers: {},
  extraReducers: {}
})

export const {} = customersSlice.actions
export default customersSlice.reducer
