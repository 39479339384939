import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

export const modalSettingsObj = {
  isLoadingOpen: "isLoadingOpen"
}

const initialState = {
  isLoadingOpen: []
}

const modalSettingsSlice = createSlice({
  name: "modalSettingsSlice",
  initialState,
  reducers: {
    setModalSettingsState(state, { payload }) {
      let { type } = payload

      switch (type) {
        case "isLoadingOpen":
          if (state[type].includes(payload.value)) {
            state[type] = state[type].filter((item) => item !== payload.value)
          } else {
            state[type].push(payload.value)
          }
          break
      }
    }
  },
  extraReducers: {}
})

export const { setModalSettingsState } = modalSettingsSlice.actions
export default modalSettingsSlice.reducer
