import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { TMJobTypesApi } from "../apis"

export const getTMJobTypes = createAsyncThunk("TMJobTypes/getTMJobTypes", async (payload) => {
  return await TMJobTypesApi.getTMJobTypes(payload)
})

export const postTMJobType = createAsyncThunk("TMJobTypes/postTMJobType", async (payload, { dispatch }) => {
  return await TMJobTypesApi.postTMJobType(payload)
})

export const getTMJobTypeById = createAsyncThunk(
  "TMJobTypes/getTMJobTypeById",
  async (payload, { rejectWithValue }) => {
    try {
      return await TMJobTypesApi.getTMJobTypeById(payload)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const putTMJobTypeById = createAsyncThunk(
  "TMJobTypes/putTMJobTypeById",
  async (payload, { rejectWithValue }) => {
    try {
      return await TMJobTypesApi.putTMJobTypeById(payload)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

const initialState = {}

const TMJobTypesSlice = createSlice({
  name: "TMJobTypesSlice",
  initialState,
  reducers: {},
  extraReducers: {}
})

export const {} = TMJobTypesSlice.actions
export default TMJobTypesSlice.reducer
