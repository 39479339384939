import Router from "next/router"
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { setCookieToken, deleteCookieToken } from "@constants"
import { AuthApi } from "../apis"
import { clearMe } from "./admins"
import { getAdminById } from "@slices/admins"

export const postLogin = createAsyncThunk("auth/postLogin", async (payload, { dispatch, rejectWithValue }) => {
  try {
    const { accessToken } = await AuthApi.postLogin(payload)
    setCookieToken(accessToken)
    return await dispatch(getAdminById())
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const getLogout = createAsyncThunk("auth/getLogout", async (payload, { dispatch, rejectWithValue }) => {
  try {
    return await AuthApi.getLogout(payload)
  } catch (error) {
    return rejectWithValue(error)
  } finally {
    deleteCookieToken()
    Router.push({ pathname: "/login" })
    dispatch(clearMe())
  }
})

export const getForgetPassword = createAsyncThunk("auth/getForgetPassword", async (payload) => {
  return await AuthApi.getForgetPassword(payload)
})

export const postOTP = createAsyncThunk("auth/postOTP", async (payload, { rejectWithValue }) => {
  try {
    return await AuthApi.postOTP(payload)
  } catch (error) {
    return rejectWithValue(error)
  }
})

const initialState = { user: undefined }

const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: {
    [postOTP.fulfilled]: (state, { meta }) => {
      if (meta?.arg) {
        state.user = meta?.arg
      }
    }
  }
})

export const {} = authSlice.actions
export default authSlice.reducer
