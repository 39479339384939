const hideScroll = {
  /* Firefox */
  scrollbarWidth: "none",
  /* IE and Edge */
  msOverflowStyle: "none",
  /* for Chrome, Safari and Opera */
  "&::-webkit-scrollbar": {
    display: "none"
  }
}

export default hideScroll
